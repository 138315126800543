<template>
  <div class="row">
    <div class="col--10 col--md-12">
      <plan-form />
    </div>
  </div>
</template>

<script>
import * as message from '@/store/support/message'
const PlanForm = () => import('@/components/PlanForm')

export default {
  components: {
    PlanForm
  },

  beforeRouteLeave(to, from, next) {
    const answer = window.confirm(message.CONFIRM_BEFORE_LEAVE)
    if (answer) {
      next()
    } else {
      next(false)
    }
  }
}
</script>
